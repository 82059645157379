
































































import { defineComponent, ref, onMounted } from '@vue/composition-api'
import { Episode } from '@/inc/types'

export default defineComponent({
  name: 'Episode',
  props: {
    content: {
      type: Object as () => Episode,
      required: true,
    },
    current: {
      type: Boolean,
      default: false,
    },
  },

  setup(_props, ctx) {
    const video = ref<HTMLVideoElement | null>(null)

    const openPopup = () => {
      ctx.root.$ee.$emit('popup-newsletter:open')
    }

    const playVideo = () => video.value && video.value.play()

    const pauseVideo = () => video.value && video.value.pause()

    const toggleVideo = () => {
      if (!_props.current) {
        return
      }

      if (video.value!.paused) {
        playVideo()
      } else {
        pauseVideo()
      }
    }

    onMounted(() => {
      if (_props.current) {
        playVideo()
      }
    })

    return {
      video,
      openPopup,
      playVideo,
      pauseVideo,
      toggleVideo,
    }
  },
})
